import React from "react"
import { graphql, StaticQuery } from "gatsby"
// import Img from "gatsby-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (

    <Layout title={siteTitle}>

        <Seo title="Sobre" keywords={[`sobre`, `gatsby`, `javascript`, `react`]} />

        <header className="page-head post-content-body">

            <h1 className="page-head-title">
                <span role="img" aria-label="Brush" style={{marginRight: '-0.3em'}}>🖌</span> +  <span role="img" aria-label="iMac">🖥</span> <br />
                Design e tecnologia
            </h1>

            <p className="text-center" style={{marginTop: '30px'}}>
                Ao longo da minha trajetória fiz com que as duas áreas ficassem cada vez mais presentes no meu dia a dia. Hoje sou capaz de criar sites completos, da concepção ao deploy, sempre buscando estar atualizado nas tecnologias e boas práticas mais recentes.
            </p>

        </header>

        <article className="post-content page-template" style={{paddingTop: '0px'}}>

            <div className="post-content-body">

                <video className="kg-width-full" autoPlay loop muted>
                    <source src="https://s3-sa-east-1.amazonaws.com/bucket.massimo.studio/videos/reel-augusto-carmo-02.mp4" type="video/mp4"/>
                </video>

                {/* <figure className="kg-card kg-image-card kg-width-full">
                    <Img
                        fluid={data.benchAccounting.childImageSharp.fluid}
                        className="kg-image"
                    />
                    <figcaption>Large imagery is at the heart of this theme</figcaption>
                </figure> */}

                {/* <h3 id="dynamic-styles">Dynamic styles</h3>

                <p>
                    London comes with photo-centric main layout best suited to
                    photography, graphics portfolios and other image-heavy uses.
                </p>

                <p>
                    Both post and page templates are light and minimal, with all the
                    focus on the content while the design of the theme gets out of the
                    way. Beneath the hood, London enjoys the full power of the{" "}
                    <a href="https://docs.ghost.org/api/handlebars-themes/">
                    Ghost Handlebars Theme API
                    </a>{" "}
                    to provide limitless customisation options and dynamic styles.
                </p>

                <p>
                    Don't forget to check out the{" "}
                    <a href="https://docs.ghost.org/integrations/">
                    Ghost Integrations Directory
                    </a>{" "}
                    for more ways to integrate Ghost with your favourite services.
                </p> */}

            </div>

        </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
